import type {
  ComboBoxParentPropsMultiple,
  ComboBoxParentPropsSingle
} from "~/components/combobox";
import type { ProjectPickerQuery } from "~/types/api";
import _ from "lodash";
import { useMemo, useCallback, useState, useEffect } from "react";
import invariant from "tiny-invariant";
import ComboBox from "~/components/combobox";
import { IconExternalLink } from "~/components/icons";
import Link from "~/components/link";
import { useDelayedValue } from "~/utils/hooks";
import { useFetcherData } from "~/utils/remix";

export type ProjectModes = "Project" | "Opportunity" | "Overhead" | "Campaign";

type BaseProps = {
  mode?: "Projects" | "Campaigns" | "Both";
  filter?:
    | "Alive or Non-Zero"
    | "All"
    | "Open"
    | "Overhead"
    | "Parent Campaigns"
    | "Sales";
  viewLink?: boolean;
  excludeId?: string;
  includeTeam?: boolean;
};
export type ProjectPickerPropsSingle = ComboBoxParentPropsSingle & BaseProps;
export type ProjectPickerPropsMulti = ComboBoxParentPropsMultiple & BaseProps;

export default function ProjectPicker({
  excludeId,
  viewLink,
  filter = "Open",
  mode = "Both",
  placeholder,
  includeTeam,
  ...rest
}: ProjectPickerPropsSingle | ProjectPickerPropsMulti) {
  const [includeId, setIncludeId] = useState(
    (rest.value as string) || (rest.defaultValue as string) || undefined
  );
  const [loading, setLoading] = useState(filter !== "All" || !!includeId);
  const [query, setQuery] = useState("");
  const search = useDelayedValue(query, { delay: 500, minLength: 3 });
  const fetcher = useFetcherData<ProjectPickerQuery>(
    filter === "All" && !search && !includeId
      ? undefined
      : "/resources/projects/picker",
    {
      includeId,
      modes:
        mode === "Projects"
          ? ["Project", "Opportunity", "Overhead"]
          : mode === "Campaigns"
            ? ["Campaign"]
            : undefined,
      search,
      filters: [filter === "All" && !search ? "None" : filter],
      includeTeam: includeTeam || false
    }
  );

  // Turn on loading
  useEffect(() => {
    if (filter === "All" && query && query !== search) {
      setLoading(true);
    } else if (filter === "All" && !search && !includeId) {
      setLoading(false);
    }
  }, [query, search, filter, includeId]);

  useEffect(() => {
    if (fetcher.state === "idle") {
      setLoading(false);
    }
  }, [fetcher.state]);

  const projects = useMemo(() => fetcher.data?.projects || [], [fetcher.data]);

  const options = useMemo(() => {
    const o = _.sortBy(
      projects,
      (p) => !p.number.startsWith("OH-"),
      "number"
    ).map((p) => ({
      value: p.id,
      label: `${p.number} ${p.name}`,
      subtitle:
        p.formerNumbers.length > 0 && `Formerly ${p.formerNumbers.join(", ")}`
    }));
    return excludeId ? o.filter((o) => o.value !== excludeId) : o;
  }, [projects, excludeId]);

  const localOnChange = useCallback(
    (name: string, value: string) => {
      invariant(!rest.multiple, "Cannot use single mode with multiple");
      const project = projects.find((p) => p.id === value);
      rest.onChange?.(name, value, project);
      setIncludeId(value);
    },
    [projects, rest]
  );

  const selected = projects.find((p) => p.id === rest.value);

  return (
    <div className="inline-flex items-center">
      <div className="flex-1">
        {rest.multiple ? (
          <ComboBox
            isLoading={loading}
            loadingMessage="Loading Projects..."
            {...rest}
            placeholder={
              loading
                ? "Loading Projects..."
                : placeholder ||
                  (mode === "Campaigns"
                    ? "Select a campaign..."
                    : "Select a project...")
            }
            multiple
            onChange={rest.onChange}
            options={loading ? [] : options}
            filterOptions={(opts, query) => {
              return opts.filter((option) => {
                return (
                  option.value.toLowerCase().includes(query.toLowerCase()) ||
                  option.label
                    ?.toString()
                    .toLowerCase()
                    .includes(query.toLowerCase()) ||
                  option.subtitle
                    ?.toString()
                    .toLowerCase()
                    .includes(query.toLowerCase())
                );
              });
            }}
          />
        ) : (
          <ComboBox
            isLoading={loading}
            loadingMessage="Loading Projects..."
            onChangeQuery={filter === "All" ? setQuery : undefined}
            noOptionsMessage={
              filter === "All" && !query
                ? "Type to search for a project..."
                : loading
                  ? "Loading Projects..."
                  : undefined
            }
            {...rest}
            placeholder={
              loading
                ? "Loading Projects..."
                : placeholder ||
                  (mode === "Campaigns"
                    ? "Select a campaign..."
                    : "Select a project...")
            }
            onChange={localOnChange}
            options={loading ? [] : options}
            filterOptions={(opts, query) => {
              return opts.filter((option) => {
                return (
                  option.value.toLowerCase().includes(query.toLowerCase()) ||
                  option.label
                    ?.toString()
                    .toLowerCase()
                    .includes(query.toLowerCase()) ||
                  option.subtitle
                    ?.toString()
                    .toLowerCase()
                    .includes(query.toLowerCase())
                );
              });
            }}
          />
        )}
      </div>
      {viewLink && selected && (
        <div>
          <Link to={`/projects/${selected.number}`} target="_blank">
            <IconExternalLink /> View
          </Link>
        </div>
      )}
    </div>
  );
}
