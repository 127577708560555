import type {
  ShowContactFieldsFragment,
  InvoiceDashProjectFieldsFragment,
  TagFieldsFragment
} from "~/types/api";
import _ from "lodash";
import { Fragment } from "react";
import { IconMap } from "~/components/icons";
import { ListGroupItem } from "~/components/list-group";
import TagRow from "~/components/tags/row";

type Props = {
  noGroups?: boolean;
  noSubsections?: boolean;
  selected?: string[];
  onClick?: (tag: TagFieldsFragment) => void;
  section: string;
  tags?: TagFieldsFragment[];
  parent?: ShowContactFieldsFragment | InvoiceDashProjectFieldsFragment;
};

export default function TagGroup({
  noGroups,
  noSubsections,
  section,
  tags,
  parent,
  onClick,
  selected
}: Props) {
  if (!tags?.length) {
    return null;
  }
  if (section === "Area" && !noSubsections) {
    const subs = _.groupBy(tags, (t) => t.subsection || "No Region Assigned");
    return (
      <>
        <ListGroupItem className="flex gap-2 font-bold">
          <div className="flex-1">{section}</div>
          <a
            href="https://files.petefowler.com/9e18a9e8-1eab-498e-843a-314c57445d69/SKMBT_C224e19022114300.png?v=1731936220"
            target="_blank"
            rel="noreferrer noopener"
          >
            <IconMap /> View Map
          </a>
        </ListGroupItem>
        {Object.keys(subs)
          .sort()
          .map((region) => (
            <Fragment key={region}>
              <ListGroupItem className="text-muted font-bold">
                {region}
              </ListGroupItem>
              {subs[region].map((t) => (
                <TagRow
                  onClick={onClick}
                  tag={t}
                  selected={selected?.includes(t.id)}
                  key={t.id}
                  parent={parent}
                />
              ))}
            </Fragment>
          ))}
      </>
    );
  }
  return (
    <>
      {!noGroups && (
        <ListGroupItem className="font-bold">{section}</ListGroupItem>
      )}
      {tags.map((tag) => (
        <TagRow
          onClick={onClick}
          tag={tag}
          selected={selected?.includes(tag.id)}
          key={tag.id}
          parent={parent}
        />
      ))}
    </>
  );
}
