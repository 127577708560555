import type {
  ContentTopicFieldsFragment,
  EditorTemplateFieldsFragment
} from "~/types/api";
import { useFetcher } from "@remix-run/react";
import { produce } from "immer";
import { useState } from "react";
import { v1 } from "uuid";
import Button from "~/components/button";
import ButtonLink from "~/components/button-link";
import ConfirmDelete from "~/components/confirm-delete";
import Heading from "~/components/heading";
import { IconHelp, IconTrash } from "~/components/icons";
import ProjectPicker from "~/components/projects/picker";
import { RemixForm, Errors, Input, Buttons } from "~/components/remix-form";
import { useCurrentUser } from "~/utils/auth";
import { BLANK_SLATE } from "~/utils/text";

interface PropsNew {
  templates: EditorTemplateFieldsFragment[];
  source?: undefined;
}

interface PropsEdit {
  templates?: undefined;
  source: ContentTopicFieldsFragment;
}

export const CONTENT_STATUSES = [
  "Published",
  "Pending Publication",
  "Ready for Publication",
  "In Process",
  "Outdated",
  "Backlog",
  "Archived"
];
export const CONTENT_STATUSES_WITH_DESCRIPTIONS = [
  {
    value: "Published",
    label: "Published",
    subtitle:
      "The content is externally available in some from, either published through a third party or through our own website. Content can only move from Ready for Publication to the Published category once there is no more action is required from the coordinator. (Ex: An article has been published through the Journal of Light Construction's print magazine, but not yet online or on our own website. While technically published and available, it is not ready for the published status until the coordinator has completed 100% of required actions with this article.)"
  },
  {
    value: "Pending Publication",
    label: "Pending Publication",
    subtitle:
      "It has been identified where this content will be published (either through an external publisher or by Pete Fowler Construction on our website). If externally published, the coordinator has confirmation from the publisher that the work has been accepted. There should be key files that clearly indicate the draft submitted to the publisher as well as the draft with the publisher's edits. The coordinator has an action to verify the edits made by publisher with the author. No matter where this content is published, the coordinator's action steps in this publication status are closing the loop and quality control. The content will remain in this status until the publishing process is completed."
  },
  {
    value: "Ready for Publication",
    label: "Ready for Publication",
    subtitle:
      "The content author has confirmed that the content drafting process is completed. This means that the content will only require further attention from the author to review changes made during the peer review or editing process with the publisher. A key content note should be made that clearly identifies what version of this content should be sent to the publisher and is to be considered the final draft. This will trigger a final CSM between the coordinator and author where the final draft will be verified, the direction of publishing is given (where is this being published), and the coordinator will update the content page status to Ready for Publication."
  },
  {
    value: "In Process",
    label: "In Process",
    subtitle:
      "Content that is actively being drafted or coordinated by the collateral page owner. Content pages in this status have an active kanban card that will be addressed within the quarter. Coordinators will be assigned content pages they are responsible for owning on a quarterly basis (thus determining which content pages are considered in process for the quarter). Content authors are welcome to have as many in process content pages as they would like, so long as they 1.) appropriately notify marketing team via the collateral inbox when action is needed to coordinate publication, 2.) are actively working on the content within the quarter. If the content author will not work on the content within the quarter, the content page status should be changed to Backlog."
  },
  {
    value: "Outdated",
    label: "Outdated",
    subtitle:
      "Content that is no longer current or accurate that requires an update of some kind before external distribution. This status is for content pages with one piece of content only, not for collections of content such as webinars or seminars that will be improved upon each time it is delivered. In an ideal state, items in this status should not be there for very long, as the problem will be sent to the collateral inbox for assignment by the team."
  },
  {
    value: "Backlog",
    label: "Backlog",
    subtitle:
      "Content that has been started in some form, but is not being actively drafted or coordinated. More work is needed on this content before it can be ready for external publication. Some older content pages with no drafted content live in this backlog category. Moving forward, content ideas with no in progress draft / started work will live on the Ad and Collateral Ideas custom list in the Collateral Project OH-024; A content page should only be made once we are ready to develop the content."
  },
  { value: "Archived", label: "Archived", subtitle: "" }
];

export const CONTENT_CATEGORIES = [
  ["Articles & Whitepapers", "Articles & Whitepapers", "articles"],
  [
    "Blog Posts / Articles / Web Pages",
    "Blog Posts / Articles / Web Pages",
    "web"
  ],
  ["Books", "Books", "books"],
  ["Case Studies", "Case Studies", "case-studies"],
  ["Employee Marketing Collateral", "Employee Marketing Collateral", "team"],
  [
    "Marketing Collateral (Brochures, Handouts, etc.)",
    "Marketing Collateral (Brochures, Handouts, etc.)",
    "collateral"
  ],
  ["Other", "Other", "other"],
  ["Seminars (Long)", "Seminars (Long)", "seminars"],
  ["Slides", "Slides", "slides"],
  ["Social Media", "Social Media", "social-media"],
  ["Webinar/Seminar (Short)", "Webinar/Seminar (Short)", "webinars"]
];

export const CONTENT_SUB_CATEGORIES = [
  "1. Property, Injury, Jobsite, Premises, and General Liability Claims",
  "2. Construction Defect Litigation",
  "3. Construction Contract Claims",
  "4. Building Inspection & Testing",
  "5. Estimating, Quality & Construction Management",
  "6. Expert Witness Work",
  "7. Construction Fundamentals",
  "8. Being Awesome at Work",
  "9. Old Webinars"
];

export default function ContentForm({
  source,
  templates
}: PropsNew | PropsEdit) {
  const currentUser = useCurrentUser();
  const [deleting, setDeleting] = useState(false);
  const fetcher = useFetcher<unknown>();
  const data = source
    ? {
        ...source,
        projectIds: source.projects.map((p) => p.id),
        ownerId: source.owner?.id,
        tagIds: source.tags.map((t) => t.id)
      }
    : {
        creatorId: currentUser.id,
        summary:
          templates.find(
            (t) => t.name === "One Minute Summary: Content & Collateral"
          )?.contentSlate || BLANK_SLATE,
        category: ""
      };

  const [projects, setProjects] = useState(
    source
      ? [
          ...source.projects.map((p) => ({ uuid: v1(), id: p.id })),
          { uuid: v1(), id: "" }
        ]
      : [{ uuid: v1(), id: "" }]
  );

  const setProject = (uuid: string, id: string) => {
    setProjects(
      produce((draft) => {
        const idx = draft.findIndex((p) => p.uuid === uuid);
        if (idx === -1) {
          return;
        }
        draft[idx].id = id;
        if (!draft.some((p) => p.id === "")) {
          draft.push({ uuid: v1(), id: "" });
        }
        if (draft.filter((p) => p.id === "").length > 1) {
          const i = draft.findIndex((p) => p.id === "");
          draft.splice(i, 1);
        }
      })
    );
  };

  const performDelete = async () =>
    fetcher.submit({ action: "delete", id: source!.id }, { method: "post" });

  const [category, setCategory] = useState(data.category);
  return (
    <div>
      <Heading title={`${source ? "Edit" : "New"} Content`}>
        <ButtonLink to="/training/390" target="_blank">
          <IconHelp /> Content & Collateral Development Training
        </ButtonLink>
      </Heading>
      <RemixForm data={data} redirect>
        <Errors />
        <div className="grid grid-cols-4 gap-8">
          <div className="col-span-2">
            <Input name="title" autoFocus />
          </div>
          <Input
            name="status"
            type="combo"
            options={CONTENT_STATUSES}
            isClearable={false}
            label="Status"
          />
          <Input name="ownerId" type="user" />
        </div>
        <div className="grid grid-cols-2 gap-8">
          <Input
            name="category"
            value={category}
            onChange={(_name, value) => setCategory(value)}
            type="combo"
            options={CONTENT_CATEGORIES}
            isClearable={false}
          />
          <Input name="tagIds" label="Tags" type="tagMulti" scope="Content" />
        </div>
        {category === "Webinar/Seminar (Short)" ? (
          <Input
            name="subcategory"
            type="combo"
            options={CONTENT_SUB_CATEGORIES}
            isClearable={false}
          />
        ) : (
          <input type="hidden" name="subcategory" value="" />
        )}
        <div className="grid grid-cols-2 gap-8">
          <Input
            name="publicUrl"
            label="Public URL (e.g. blog post, landing page)"
          />
          <div>
            <label>Associated Projects</label>
            <div className="space-y-2">
              {projects.map((p, i) => (
                <ProjectPicker
                  key={p.uuid}
                  filter="All"
                  placeholder={
                    i === 0 ? "Add a project..." : "Add another project..."
                  }
                  name={p.uuid}
                  value={p.id}
                  onChange={(_name, value) => setProject(p.uuid, value)}
                />
              ))}
            </div>
            <input type="hidden" name="projectIds" />
            {projects
              .filter((p) => p.id)
              .map((p) => (
                <input
                  type="hidden"
                  name="projectIds"
                  value={p.id}
                  key={p.uuid}
                />
              ))}
          </div>
        </div>
        <Input
          name="summary"
          type="slate"
          placeholder="Enter a summary of this content..."
        />
        <Buttons
          redirectTo={source ? `/content/${source.id}` : "/content"}
          rightContent={
            source && (
              <Button mode="danger" onClick={() => setDeleting(true)}>
                <IconTrash /> Delete Content
              </Button>
            )
          }
        />
      </RemixForm>
      {deleting && (
        <ConfirmDelete
          onConfirm={performDelete}
          fetcher={fetcher}
          onClose={() => setDeleting(false)}
          message="Are you sure you want to delete this piece of content? This will permanently delete all associated information including any notes below. You should probably just change the status to Archived instead."
        />
      )}
    </div>
  );
}
